import React from "react";

const Testimonials = () => {
  return (
    <section className="testimonial-section">
      <div className="container">
        <h2 className="heading">Testimonials</h2>
        <p className="subheading">
          A highlight of what our students and partners think about us
        </p>

        <div className="card-container">
          <div className="card">
            <span className="card-quotes">❝</span>
            <div className="card-content">
              <p className="card-desc">
                I learned about Country Aspire from a friend, studying in Canada and was
                really happy with them. Their counsellor gave us very useful
                tips for succeeding in IELTS with high scores. I would surely
                recommend Country Aspire to any applicant who aspires to study abroad
                and also recommend them to attend the education fair and loan
                Mela organised by Country Aspire.
              </p>
              <h4 className="card-name">Yasir Khan</h4>
              <p className="card-address">University of Waikato, New Zealand</p>
            </div>
          </div>
          <div className="card">
            <h3 className="card-quotes">❝</h3>
            <div className="card-content">
              <p className="card-desc">
                Thanks to the entire team of Country Aspire and the Counsellor who
                guided me from my first day till the date of examination. The
                preparation and classes were really helpful. The teachers are
                very knowledgeable and Country Aspire helped me to Gain 302 in GRE and 7
                bands in IELTS. Thank you for your kind support
              </p>
              <h4 className="card-name">Mithil Dilip Shah</h4>
              <p className="card-address">GRE & IELTS</p>
            </div>
          </div>
          <div className="card">
            <h3 className="card-quotes">❝</h3>
            <div className="card-content">
              <p className="card-desc">
                I started my IELTS prep. at Country Aspire Thane. The staff here helped
                me fill my exam form and post clearing the exam Namita Ma'am
                helped me me with college shortlisting and application process;
                the Visa Counselor helped me with documents and I secured the
                Visa. Overall I found the staff very helpful. It really helped
                me in being positive throughout the entire process.
              </p>
              <h4 className="card-name">Simran Kaur Chahal</h4>
              <p className="card-address">Sheridan College, PADMK, Canada</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

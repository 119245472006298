import React from 'react';
import ServicesData from './ServicesData';
import HomeContact from '../../Components/ContactForm/HomeContact';
import { MdTouchApp } from "react-icons/md";

const Services = () => {
  return (
    <section id='services' className='services'> 
    <div className="services-container-content">
    <div className="services-main-head">
      <h2>Our Services</h2>
      <p>
      Our suite of services offers a comprehensive support system for your educational pursuits. From personalized one-on-one counseling, meticulously tailored to your aspirations and goals, to strategic profile assessments and course shortlisting, we navigate the complexities of the application and admission processes with expert finesse. Our guidance extends to comprehensive visa support, facilitating seamless transitions for college changes and extensions, PGWP submissions, PR settlement pathways, and post-landing services, including accommodation arrangements and local SIM card assistance. Trust us to optimize every facet of your educational journey for success and fulfillment.
      </p>
      </div>
    {/* <div className="services-container"> */}
      
    <div className="services-wrapper">
      {
        ServicesData.map((item, index)=>{
          return (
              <div className=" services-content" key={index}>
                <div className="card-profile">
                {/* <div className="card-img">{item.image}</div> */}
                <img src={`./Images/${item.image}`} width={80} height={80} />
                    <div className="card-heading">
                        <div className="card-subheading">
                  <h2 className='heading'>{item.title}</h2>
                        </div>
                        <div className="card-subheading">
                  <h4>Touch to view <MdTouchApp className='card-icon'/></h4>
                        </div>
                    </div>
                <div className="services-image">
                  <img src={item.image} alt="" />
                </div>
                <div className="services-text">
                  <div className="head">
                    <h3>{item.title}</h3>
                  </div>
                  <div className="sub-head">
                    <p>{item.description}</p>
                  </div>
                </div>
                </div>

              </div>
          )
        })
      }
    </div> 
    <div className='after-card-content'>
    <p>"Embark on your global education journey with us. Discover a range of services designed to transform your dreams into reality. From personalized counseling to post-landing support, we provide the guidance you need to make your study abroad experience a success. Start your adventure toward a world-class education today!"
</p>
    </div>
    </div>
    <HomeContact />
      
    </section>
  )
}

export default Services
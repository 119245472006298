import React from "react";

const CustomHeroSection = ({ heroSectionData }) => {
  return (
    <section className="student-hero-section">
      <div
        className="hero-background"
        style={{
          backgroundImage:
            `linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(${heroSectionData.backgroundURL})`,
        }}
      >
        <div className="hero-content">
          <h1 className="hero-heading">
            {heroSectionData.heading}
          </h1>
          <p className="hero-description">
            {heroSectionData.description}
          </p>
          <button className="hero-button">{heroSectionData.buttonText}</button>
        </div>
      </div>
    </section>
  );
};

export default CustomHeroSection;

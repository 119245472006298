import Home from "./Pages/Home";
import Privacy from "./Pages/Privacy";
import Students from "./Pages/Students";
import OurStory from "./Components/AboutUs/OurStory";
import RecuritmentPartner from "./Pages/RecuritmentPartner";
import PartnerUniversities from "./Pages/PartnerUniversities";
import ContactForm from "./Pages/ContactForm";
import { Header, Footer } from "./Components";
import { Route, Routes } from "react-router-dom";
import WhyUs from "./Components/WhyUs/WhyUs";
import Services from "./Components/Services/Services";
import Destination from "./Components/Destinations/Destination";

const App = () => {
  return (
    <div>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/students" element={<Students />} />
        <Route path="/whyusstudents" element={<WhyUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/privacy-and-policy" element={<Privacy />} />
        <Route path="/recruitment-partner" element={<RecuritmentPartner />} />
        <Route path="/partner-universities" element={<PartnerUniversities />} />
        <Route path="/destinations" element={<Destination />} />
        <Route path="/ourstory" element={<OurStory />} />
        <Route path="/contact/student" element={<ContactForm connectAs="Student" />} />
        <Route path="/contact/institution" element={<ContactForm connectAs="Institution" />} />
        <Route path="/contact/agent" element={<ContactForm connectAs="Agent" />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;

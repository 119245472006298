import React from 'react'
import DestinationData from './DestinationData';

const Destination = () => {
  return (
    <section id='destinations'className='destinations'>
      <div className="map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2083.3852233191587!2d77.18732226285455!3d28.575097375941365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1d81eeaa6bcb%3A0xb7c66df28db2e250!2s102%2C%20Africa%20Ave%2C%20Netaji%20Nagar%20Market%2C%20Netaji%20Nagar%2C%20New%20Delhi%2C%20Delhi%20110023!5e0!3m2!1sen!2sin!4v1706281591390!5m2!1sen!2sin"
       width="100%"
       height="450" 
       style={{border:"0" }}
       allowFullScreen="" 
       loading="lazy" 
       referrerPolicy="no-referrer-when-downgrade">

       </iframe>
      </div>
      
      <div className="destinations-container">
        <div className="destinations-heading">
        <h2>Our Destinations</h2>
        <p>
            Country Aspire specializes in guiding students towards exceptional study abroad opportunities in Canada, the USA, Australia, and the UK. Choosing the right destination is pivotal to your study abroad journey, and we provide expert advice and support to help you make informed decisions. With our assistance, you can explore the diverse educational landscapes and find the perfect fit for your academic and personal growth. Trust Country Aspire for a rewarding international study experience.
        </p>
        </div>
        <div className="destination-wrapper">
          {
            DestinationData.map((item, index)=>{
              return(
                
                  
                <div className="destinations-card" key={index}  style={{
                  background: `linear-gradient(rgba(24, 23, 23, 0.144), rgba(14, 13, 13, 0.548)), url(${item.image})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat"
                  }}>
                    <div className="destination-card">

                    
                    <div className="destinations-head">
                        <h2 className='sub-head'>Touch me</h2>
                   </div>
                  
                  <div className="destinations-info">
                     <h3>{item.title}</h3>
                     <p>{item.description}</p>
                  </div>
                  </div>
                </div>
                
                 

              )
            })
          }

        </div>
      </div>
    </section>
  )
}

export default Destination
export const PartnerUniversityData = [
  {
    id: 1,
    src: "https://uploads-ssl.webflow.com/61c9e5791e48c43d0e1b68dc/61d099773226d4969ffad6b2_University%20of%20cincinnati.png",
    alt: "University Logo 1",
  },
  {
    id: 2,
    src: "https://uploads-ssl.webflow.com/61c9e5791e48c43d0e1b68dc/61d099785599fffdfd33c3d6_Missourri%20university%20of%20Science%20and%20Technology.png",
    alt: "University Logo 2",
  },
  {
    id: 3,
    src: "https://uploads-ssl.webflow.com/61c9e5791e48c43d0e1b68dc/61d7c96e15bbc4db8fb9f857_New_Jersey_IT_seal.svg-p-500.png",
    alt: "University Logo 3",
  },
  {
    id: 4,
    src: "https://uploads-ssl.webflow.com/61c9e5791e48c43d0e1b68dc/61d099734d566d88c9f3da96_Cleveland%20state%20university.png",
    alt: "University Logo 4",
  },
  {
    id: 5,
    src: "https://uploads-ssl.webflow.com/61c9e5791e48c43d0e1b68dc/61f75f303504a25e83e4d427_61e80fd2f5046f12cfb597d9_download%20(1).png",
    alt: "University Logo 5",
  },
  {
    id: 6,
    src: "https://uploads-ssl.webflow.com/61c9e5791e48c43d0e1b68dc/61d099735a116c822e49bfaa_San%20jose%20state%20university.png",
    alt: "University Logo 6",
  },
  {
    id: 7,
    src: "https://uploads-ssl.webflow.com/61c9e5791e48c43d0e1b68dc/61d7cb2d0a7f47735cb30c97_2560px-George_Mason_University_logo.svg-p-500.png",
    alt: "University Logo 7",
  },
  {
    id: 8,
    src: "https://uploads-ssl.webflow.com/61c9e5791e48c43d0e1b68dc/61d09973d89a5085204933ea_Ohio%20University.png",
    alt: "University Logo 8",
  },
  {
    id: 9,
    src: "https://uploads-ssl.webflow.com/61c9e5791e48c43d0e1b68dc/61d7c96e751800124242359a_1200px-University_of_South_Florida_seal.svg-p-500.png",
    alt: "University Logo 9",
  },
];

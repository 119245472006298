import React from "react";
import ShakeHandsLines from "../../icons/ShakeHandsLines";
import Global from "../../icons/Global";
import UserFollowLine from "../../icons/UserFollowLine";

const WhyUs = () => {
  return (
    <section id="whyusstudents" className="whyus-section">
      <div className="container">
        <h2 className="heading">Why choose us?</h2>
        <div className="card-container">
          <div className="card">
            <div className="card-profile">
              <UserFollowLine className="card-profile-icon"/>
              <h3 className="card-profile-title">
              Expert Guidance Assistance:
              </h3>
            </div>
            <p className="card-profile-desc">
            Clients can benefit from the founder's extensive experience, ensuring that they receive insights and advice from professionals deeply rooted in the industry. This expertise can contribute to making well-informed decisions regarding education and career paths.
            </p>
          </div>
          <div className="card">
            <div className="card-profile">
              <ShakeHandsLines className="card-profile-icon"/>
              <h3 className="card-profile-title">
                Global Network:
              </h3>
            </div>
            <p className="card-profile-desc">
            The founder's background in working with top institutions positions Country Aspire as a gateway to prestigious universities in Canada, the USA, Australia, and the UK. This global network can provide clients with a range of options for pursuing their education.
            </p>
          </div>
          <div className="card">
            <div className="card-profile">
              <Global className="card-profile-icon"/>
              <h3 className="card-profile-title">
              Comprehensive Services:
              </h3>
            </div>
            <p className="card-profile-desc">
            Country Aspire provides a wide range of services, from one-on-one counseling to post-landing assistance. This comprehensive approach ensures that clients receive support and guidance at every step of their study abroad journey, making the process smoother and more manageable.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;

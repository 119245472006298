import React from 'react';
import ShakeHandsLines from "../../icons/ShakeHandsLines";
import Global from "../../icons/Global";
import UserFollowLine from "../../icons/UserFollowLine";

const OurStory = () => {
  return (
    <div id='ourStory'>
      <div className="headingg">
        <h1>About Us</h1>
        <h2>Our Story: Bridging Dreams, Shaping Futures</h2>
        <div className='head-btn'>
          <a href="">
          Why Choose Us
            </a>
            </div>
      </div>
      <div className="aboutus-container">
        <p>
          <h3 style={{ display: "inline-block" }}>At Country Aspire,</h3> founded by industry veterans with a combined experience since
          2016, we bring a wealth of knowledge and expertise in international education. Our
          founders have a rich background, with one having worked with multiple leading
          universities in the USA and Canada, while the other has been a key player in the study
          abroad industry. Now, they have come together to launch a platform that goes
          beyond the ordinary, offering comprehensive services for students aspiring to study
          abroad.
        </p>
        <h2>
          Empowering Global Futures: A Visionary Platform in International Education</h2>
        <p>Established by industry veterans with a combined experience dating back to 2016, our organization stands as a beacon of knowledge and expertise in the realm of international education. The brainchild of our founders, each bringing a wealth of experience from diverse backgrounds, this platform is a testament to their commitment to redefining the study abroad experience.

        </p>

        <h2>
          A Fusion of Expertise: Our Founders' Backgrounds
        </h2>

        <p>
          Our founders boast an impressive background. One has forged collaborations with leading universities in the USA and Canada, contributing significantly to the evolution of academic programs. The other has been a key player in the study abroad industry, influencing its trajectory and dynamics. Together, they have united to launch a platform that transcends the ordinary, offering comprehensive services for students aspiring to study abroad.
        </p>

        <h2>
          Beyond Boundaries: Crafting a Transformative Educational Experience
        </h2>
        <p>
          More than a service provider, our platform is a visionary partner in the educational journey. Recognizing the transformative power of international education, our mission is to facilitate a seamless and enriching experience for students venturing beyond their home countries for academic pursuits.
        </p>
        <h2>
          Holistic Solutions for Every Step: Navigating the Journey Abroad
        </h2>
        <p>
          Our commitment goes beyond facilitating study abroad opportunities. The platform is designed to be a holistic solution, addressing challenges associated with studying in a foreign country. From academic guidance and program selection to visa assistance and cultural integration, our services encompass every facet of the student's journey.
        </p>
        <h2>
          Education Knows No Boundaries: Fostering a Global Perspective
        </h2>
        <p>
          We believe that education knows no boundaries and has the potential to shape a global perspective. By fostering an environment of inclusivity, support, and personalized attention, we empower students to navigate the complexities of international education with confidence.
        </p>
        <h2>
          Join Us on the Odyssey: Beyond the Ordinary
        </h2>
        <p>
          This platform is an invitation to students who dare to dream beyond borders. It's not just about studying abroad; it's about partnering in the educational journey, opening doors to new horizons, and enriching the lives of students on a transformative educational odyssey that extends far beyond the ordinary. With a commitment to excellence and a passion for empowering the next generation of global leaders, we invite you to embark on this journey with us.
        </p>
      </div>
      <section className="whyus-section">
      <div className="container">
        <h2 className="heading">Why choose us?</h2>
        <div className="card-container">
          <div className="card">
            <div className="card-profile">
              <UserFollowLine className="card-profile-icon"/>
              <h3 className="card-profile-title">
              Holistic Approach:
              </h3>
            </div>
            <p className="card-profile-desc">
            At Country Aspire, our founders' vision extends far beyond providing standard services. We are committed to offering a holistic approach that encompasses every stage of your journey – from initial counseling to post-settlement support. Our focus is not only on facilitating your study abroad experience but ensuring your overall well-being and success.
            </p>
          </div>
          <div className="card">
            <div className="card-profile">
              <ShakeHandsLines className="card-profile-icon"/>
              <h3 className="card-profile-title">
                Canada Specialization:
              </h3>
            </div>
            <p className="card-profile-desc">
            With a dedicated focus on Canadian institutions, Country Aspire brings a wealth of specialized expertise to guide you through the distinctive opportunities this country has to offer. Whether you are exploring academic programs, seeking financial aid, or preparing for your Canadian adventure, our specialized knowledge ensures a smooth and tailored experience.
            </p>
          </div>
          <div className="card">
            <div className="card-profile">
              <Global className="card-profile-icon"/>
              <h3 className="card-profile-title">
              Student-Centric Values:
              </h3>
            </div>
            <p className="card-profile-desc">
            We are driven by the vision of extending our reach to maximum students, transcending barriers, and making study abroad dreams a reality for all. Our services are designed to be inclusive, supportive, and tailored to meet the unique needs and aspirations of each student.
            </p>
          </div>
        </div>
      </div>
    </section>
    </div>

  )
}

export default OurStory
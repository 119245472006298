import React from "react";
import { PrivacyAndPolicy } from "../Components";
import HomeContact from "../Components/ContactForm/HomeContact";

const Privacy = () => {
  return (
    <div className="privacy-policy-bg">
      <PrivacyAndPolicy />
      <HomeContact />
    </div>
  );
};

export default Privacy;

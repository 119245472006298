import React from "react";
import {
  CustomHeroSection,
  OverviewSection,
  DetailedView,
  PartnerUniversity,
  ContactForm,
} from "../Components";

const Students = () => {
  const heroSectionData = {
    backgroundURL: "/Images/student-hero-section.jpg",
    heading: "Reach your Dream College Abroad without hassle",
    description:
      "Country Aspire helps you every step of the way - starting from test preparation to finding the right universities and applying for a visa. With our 20+ years of experience and 750+ strong university network, getting into your dream college is super easy!",
    buttonText: "Start your journey",
  };

  return (
    <>
      <CustomHeroSection heroSectionData={heroSectionData} />
      <OverviewSection />
      <DetailedView />
      <PartnerUniversity />
      <ContactForm />
    </>
  );
};

export default Students;

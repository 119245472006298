import React, { useState } from 'react';
import FaqsData from './FaqsData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faAngleDown } from '@fortawesome/free-solid-svg-icons';


const Accordian = () => {
    const [activeAccordian, setActiveAccordian] = useState(null);

    const toggleAccordian = (index) => {
        setActiveAccordian((prevIndex) => (prevIndex === index ? null : index));
    };

  return (
    <section id='accordian' className="accordian">
        <div className="accordian-container">
            
        <div className="accordian-left">
            <div className="accordian-main-head">
                <h2>Got questions? Find answers.</h2>
            </div>
            {
                FaqsData.map((item, index)=>{
                    
                    return(
                        <div className="accordian-content" key={index}>
                           <div class="accordian-faq">
                             <div class="question" onClick={()=> toggleAccordian(index)}>
                                    <h4>{item.question}</h4>
                                    <FontAwesomeIcon icon={faAngleDown} className={`faqs-icon ${activeAccordian === index ? 'active' : ''}`} />
                             </div>
                              <div className={`answer ${activeAccordian === index ? 'open' : ''}`}>
                                     <p>{item.answer}</p>
                             </div>
                            </div>
                        </div>
                    )
                })  
            }
            

        </div>
        <div className="accordian-right">
            <img src='./Images/frequently.png' alt="" />
        </div>
        </div>

    </section>
  )
}

export default Accordian
import React from "react";
import {
  CustomHeroSection,
  OverviewSection,
  DetailedView,
  PartnerUniversity,
  ContactForm,
} from "../Components";

const RecuritmentPartner = () => {
  const heroSectionData = {
    backgroundURL: "/Images/recruitment-partner.jpg",
    heading:
      "Grow quickly, Manage easily- Make your students happy with Country Aspire!!",
    description:
      "Experience a fast, transparent, and smooth application process along with the best commissions. With GeeBee, you can just focus on your students and leave the rest to us. With our 20+ years of experience and a large network of 750+ Universities, your success is guaranteed!",
    buttonText: "Partner with us",
  };

  return (
    <>
      <CustomHeroSection heroSectionData={heroSectionData} />
      <OverviewSection />
      <DetailedView />
      <PartnerUniversity />
      <ContactForm />
    </>
  );
};

export default RecuritmentPartner;

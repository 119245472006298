const DestinationData = [
    {
        id:1,
        title:"Canada",
        description:"Canada's education system stands out for its emphasis on inclusivity, diversity, and innovation, fostering a multicultural learning environment that champions equity and access. Prioritizing bilingualism and multiculturalism, it promotes holistic development, critical thinking, and experiential learning from kindergarten to post-secondary levels. Institutions emphasize research-driven practices and collaborative learning, integrating indigenous perspectives to honor the country's cultural heritage. Canada's educational landscape serves as a global model for progressive policies, a student-centered approach, and unwavering commitment to excellence, making it a top choice for international students.",
        image: "https://images.pexels.com/photos/1868676/pexels-photo-1868676.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        button:"Canada"
    },
    {
        id:2,
        title:"USA",
        description:"Embark on a journey through the diverse academic landscape of the USA, renowned for its prestigious universities and innovative learning environments. From coast to coast, students encounter a plethora of educational opportunities tailored to their interests and aspirations. Whether in bustling urban centers or serene rural campuses, American universities offer world-class programs across a wide array of disciplines. Embrace a culture of academic excellence where groundbreaking research intersects with dynamic teaching methodologies. Engage with leading scholars and industry experts, enriching your academic experience and preparing for future endeavors. In the USA, explore, learn, and thrive amidst a vibrant tapestry of intellectual exploration.",
image:"https://c4.wallpaperflare.com/wallpaper/598/964/1001/new-york-city-statue-of-liberty-071570-wallpaper-preview.jpg",
        button:"USA"
    },
    {
        id:3,
        title:"Australia",
        description:"In Australia, embrace a vibrant cultural tapestry while pursuing educational excellence. Renowned for its diverse and inclusive society, Australia offers a dynamic learning environment that celebrates multiculturalism and fosters global perspectives. From stunning natural landscapes to bustling urban hubs, students immerse themselves in a rich blend of traditions and modernity. Australian universities prioritize innovation and experiential learning, equipping students with practical skills and theoretical knowledge. Engage with world-class faculty and cutting-edge research initiatives while enjoying a high quality of life. Embrace the warmth of Australian hospitality and seize opportunities to thrive academically and culturally in this welcoming nation.",
        image:"https://c1.wallpaperflare.com/preview/740/948/941/architecture-australia-building-photo.jpg",
        button:"Australia"
    },
    {
        id:4,
        title:"Uk",
        description:"Immerse yourself in the rich tradition of academic brilliance found in the UK. Renowned for its esteemed universities and innovative research, the UK offers a diverse and dynamic educational landscape. From ancient institutions steeped in history to cutting-edge research centers, students can access world-class education across various disciplines. The UK's academic environment fosters critical thinking, creativity, and global perspectives, shaping tomorrow's leaders and innovators. Whether pursuing undergraduate, postgraduate, or research programs, students engage with top scholars and benefit from state-of-the-art facilities. Embrace the legacy of academic excellence and unlock boundless opportunities for personal and intellectual growth in the UK.",
        image: "https://c1.wallpaperflare.com/preview/947/990/575/urban-building-city-london.jpg",
        button:"Uk"

    },

]

export default DestinationData;
import { PartnerUniversityData } from './partnerUniversityData';

const PartnerUniversity = () => {
    // Split the universities array into two separate arrays for the rows
    const universitiesRow1 = PartnerUniversityData.slice(0, 5);
    const universitiesRow2 = PartnerUniversityData.slice(5);

    return (
        <section id='partner-universities-section' className="partner-universities-section">
            <div className="container">
                <h2 className="section-heading">Our Top Partner Universities</h2>
                <div className="logo-container">
                    <div className="logo-row">
                        {universitiesRow1.map((university) => (
                            <img
                                key={university.id}
                                src={university.src}
                                alt={university.alt}
                                className="university-logo"
                            />
                        ))}
                    </div>
                    <div className="logo-row">
                        {universitiesRow2.map((university) => (
                            <img
                                key={university.id}
                                src={university.src}
                                alt={university.alt}
                                className="university-logo"
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PartnerUniversity
import React from 'react';

const HeroSection = () => {
    return (
        <section className="hero-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h1>
                        Welcome to Country Aspire</h1>
                        <h2>Where Dreams Soar and Futures Ignite!</h2>
                        <p>We Help Students Get Admitted Into the Best International Educational Institutions</p>
                        <a href="#" className="btn">Get Started</a>
                    </div>
                    <div className="col-md-6">
                        <img src="./Images/hurray.svg" alt="Studying Abroad" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export { HeroSection };

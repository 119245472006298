import React from 'react'

const DetailedView = () => {
    return (
        <>
            <section className="study-abroad-section left">
                <div className="container">
                    <div className="content">
                        <div className="image-container">
                            {/* <img src="https://i.pinimg.com/564x/ab/07/ca/ab07ca1bba3fbe586d7ad2468a55ab2c.jpg" alt="Image" /> */}
                            <img src="./Images/face.png" alt="Image" />
                        </div>
                        <div className="text-container">
                            <div className="heading">For Students</div>
                            <div className="subheading">Receive comprehensive guidance throughout your entire study abroad journey, from the initial stages to completion.</div>
                            <p className="description">Allow Country Aspire to assist you with test preparation, university counseling, application filing, visa processing, and other essential steps, ensuring a seamless path for you to join your desired international college!</p>
                            <div className="services">
                                <div className="service">Test Preparation</div>
                                <div className="service">Counselling Services</div>
                                <div className="service">Presence in 10+ Countries</div>
                            </div>
                            <button className="start-now-btn">Start Now</button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="study-abroad-section">
                <div className="container">
                    <div className="content">
                        <div className="image-container">
                            <img src="./Images/graduate.jfif" alt="Detailed View" />
                        </div>
                        <div className="text-container">
                            <div className="heading">For Recruitment Partners</div>
                            <div className="subheading">Impress your students with Country Aspire's reliable admission process.</div>
                            <p className="description">Eliminate concerns about trust and transparency—We treat your business as our own. With Country Aspire's extensive network of 750+ universities, seasoned counselors, and committed customer support, you can concentrate on expanding your student reach, leaving the processing part to us!</p>
                            <div className="services">
                                <div className="service">Best Turnaround Time</div>
                                <div className="service">Best Commissions</div>
                                <div className="service">Network of 750+ Universities</div>
                            </div>
                            <button className="start-now-btn">Explore More</button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="study-abroad-section left">
                <div className="container">
                    <div className="content">
                        <div className="image-container">
                            <img src="./Images/studyabroad.gif" alt="Detailed View" />
                        </div>
                        <div className="text-container">
                            <div className="heading">For Universities</div>
                            <div className="subheading">Draw in top-tier, thoroughly screened students from around the world.</div>
                            <p className="description">Benefit from the assurance of high-quality, screened students with Country Aspire's extensive network of 3500+ Counselling Service Providers (CSPs). Our renowned education fairs, press releases, and webinars will elevate your global presence to unprecedented levels!</p>
                            <div className="services">
                                <div className="service">100000+ of Applications each year</div>
                                <div className="service">Year Long Promotion Drives</div>
                                <div className="service">Trusted by 750+ Universities</div>
                            </div>
                            <button className="start-now-btn">Partner with us</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DetailedView
const FaqsData = [
    {
        id :1,
        question : "What is Country Aspire?",
        answer : "Country Aspire is an educational platform founded by industry veterans in 2016. We provide comprehensive services and expert guidance for students aspiring to study abroad, leveraging our founders' rich background and extensive experience in international education."
    },
    {
        id :2,
        question : "Who are the founders of Country Aspire?",
        answer : " The founders of Country Aspire are industry veterans with a combined experience since 2016. One founder has worked with leading universities in the USA and Canada, while the other has been a key player in the study abroad industry."
    },
    {
        id :3,
        question : "What sets Country Aspire apart?",
        answer : "Country Aspire stands out by offering more than the ordinary. We provide a wealth of knowledge, expertise, and comprehensive services to students, ensuring a well-rounded approach to their study abroad journey."
    },
    {
        id :4,
        question : "What services does Country Aspire offer?",
        answer : "Country Aspire offers a range of comprehensive services, including expert guidance, assistance with university applications, one-on-one counseling, post-landing support, and more. We cover every aspect of the study abroad process to make the journey smoother for students."
    },
    {
        id :5,
        question : " How can Country Aspire help students aspiring to study abroad?",
        answer : "Country Aspire assists students in making well-informed decisions about education and career paths. Our platform serves as a gateway to prestigious universities in the USA, Canada, and beyond. We go beyond the ordinary to provide personalized support at every step of the study abroad journey."
    },
    {
        id :6,
        question : "How can I get started with Country Aspire's services?",
        answer : "To get started, you can explore our website and reach out to us for personalized assistance. Whether you are in the early stages of considering studying abroad or need specific guidance, Country Aspire is here to support you."
    }
]

export default FaqsData;
import React from 'react';

const OverviewSection = () => {
    return (
        <section className="education-section">
            <div className="container">
                <h2 className="heading">Open Doors to Quality International Education</h2>
                <p className="subheading">We serve across the globe with Students, Universities, and Recruitment Partners in our network</p>
                <div className="card-container">
                    <div className="card">
                        <h3 className="card-value">2.5 Lakh</h3>
                        <p className="card-label">Students helped</p>
                    </div>
                    <div className="card">
                        <h3 className="card-value">3500+</h3>
                        <p className="card-label">Recruitment Partners</p>
                    </div>
                    <div className="card">
                        <h3 className="card-value">750+</h3>
                        <p className="card-label">Partner Universities</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OverviewSection;

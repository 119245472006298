import React from "react";
import {
  CustomHeroSection,
  PartnerUniversity,
}
 from "../Components";
import HomeContact from "../Components/ContactForm/HomeContact";

const PartnerUniversities = () => {
  const heroSectionData = {
    backgroundURL: "/Images/partner-universities.jpg",
    heading:
      "Increase your global reach- get seen by talented students around the world!",
    description:
      "GeeBee helps Universities get high-quality vetted student applications. With our 20+years of experience and a network of 3500+ recruitment partners, we’ll take your global presence to new heights!",
    buttonText: "Partner with us",
  };

  return (
    <>
      <CustomHeroSection heroSectionData={heroSectionData} />
      <PartnerUniversity />
      <HomeContact />
    </>
  );
};

export default PartnerUniversities;

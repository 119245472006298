import React, { useEffect, useState } from 'react';
import Phone from '../icons/Phone';
import Email from '../icons/Email';
import Location from '../icons/Location';
import { Facebook, Instagram, Twitter } from '../icons';
import { useNavigate } from 'react-router-dom';

const ContactForm = ({ connectAs }) => {
  const navigate = useNavigate();
  const [action, setAction] = useState(connectAs);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [education, setEducation] = useState('');
  const [interest, setInterest] = useState('');
  const [score, setScore] = useState('');

  const [institution, setInstitution] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [addInformation, setAddInformation] = useState('');

  const [company, setCompany] = useState('');
  const [area, setArea] = useState('');
  const [recruitment, setRecruitment] = useState('');
  const [services, setServices] = useState('');

  const handleSubmit = (e) => {
    const user = {
      name: firstName + ' ' + lastName,
      email: email,
      phone: phone,
      education: education,
      interest: interest,
      score: score,
      institution: institution,
      contactPerson: contactPerson,
      addInformation: addInformation,
      company: company,
      area: area,
      recruitment: recruitment,
      services: services,
    };
  };

  useEffect(() => {
    setAction(connectAs);
  }, [connectAs]);

  return (
    <section id="contact-section">
      <div className="contact-main-container">
        <div className="contact-main-head">
          <h2>Contact Us</h2>
          <p>Have any questions or comments? Feel free to drop us a message!</p>
        </div>
        <div className="contact-section-sub-container">
          <div className="form-container-left">
            <div className="form-container-left-top">
              <h3 className="form-container-left-top-title">
                Contact Information
              </h3>
              <p className="form-container-left-top-subtitle">
                Complete the form, and our team will reach out to you within the
                next 24 hours.
              </p>
            </div>
            <ul className="form-container-left-middle">
              <li>
                <Phone />
                +0123 4567 8910
              </li>
              <li>
                <Email />
                help@countryaspire.com
              </li>
              <li>
                <span>
                  <Location />
                </span>
                102 Africa Avenue, New Delhi
              </li>
            </ul>
            <div className="form-container-left-bottom">
              <a href="#">
                <Facebook className="facebook" />
              </a>
              <a href="#">
                <Instagram />
              </a>
              <a href="#">
                <Twitter />
              </a>
            </div>
          </div>

          <div className="contact-wrapper">
            <div className="contact-container">
              <form className="form" onSubmit={handleSubmit}>
                <div className="contact-header">
                  <div className="text"> {action}</div>
                  <div className="underline"></div>
                </div>

                <div className="contact-as-a">
                  <h3>You can connect with us as a</h3>
                </div>

                <div className="submit-container">
                  <div
                    className={
                      action === 'Student' ? ' submit  ' : 'submit gray '
                    }
                    onClick={() => {
                      navigate('/contact/student');
                    }}
                  >
                    Student
                  </div>

                  <div
                    className={
                      action === 'Institution' ? 'submit' : 'submit gray'
                    }
                    onClick={() => {
                      navigate('/contact/institution');
                    }}
                  >
                    Institution
                  </div>

                  <div
                    className={action === 'Agent' ? 'submit' : ' submit gray'}
                    onClick={() => {
                      navigate('/contact/agent');
                    }}
                  >
                    Agent
                  </div>
                </div>

                <div className="inputs">
                  {action === 'Institution' ? (
                    <div className="empty-div"></div>
                  ) : (
                    <div className="input">
                      <input
                        type="text"
                        placeholder="First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  )}

                  {action === 'Institution' ? (
                    <div className="empty-div"></div>
                  ) : (
                    <div className="input">
                      <input
                        type="text"
                        placeholder="Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  )}

                  {action === 'Student' || action === 'Agent' ? (
                    <div className="empty-div"></div>
                  ) : (
                    <div className="input">
                      <input
                        type="text"
                        placeholder="Enter Your Institution Name"
                        onChange={(e) => setInstitution(e.target.value)}
                      />
                    </div>
                  )}

                  {action === 'Student' || action === 'Agent' ? (
                    <div className="empty-div"></div>
                  ) : (
                    <div className="input">
                      <input
                        type="text"
                        placeholder="Contact Person"
                        onChange={(e) => setContactPerson(e.target.value)}
                      />
                    </div>
                  )}

                  <div className="input">
                    <input
                      type="email"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="input">
                    <input
                      type="tel"
                      placeholder="Phone Number"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>

                  {action === 'Student' || action === 'Institution' ? (
                    <div className="empty-div"></div>
                  ) : (
                    <div className="input">
                      <input
                        type="text"
                        placeholder="Company Name"
                        onChange={(e) => setCompany(e.target.value)}
                      />
                    </div>
                  )}

                  {action === 'Institution' || action === 'Student' ? (
                    <div className="empty-div"></div>
                  ) : (
                    <div className="input">
                      <input
                        type="text"
                        placeholder="Country/Area of Operation"
                        onChange={(e) => setArea(e.target.value)}
                      />
                    </div>
                  )}

                  {action === 'Institution' || action === 'Student' ? (
                    <div className="empty-div"></div>
                  ) : (
                    <div className="input">
                      <select
                        id="dropdown-menu"
                        onChange={(e) => setRecruitment(e.target.value)}
                      >
                        <option
                          selected
                          disabled
                          hidden
                          value="Number of years in recruitment"
                        >
                          Number of years in recruitment
                        </option>
                        <option value="less-than-1">Less than 1 year</option>
                        <option value="1-3">1-3 years</option>
                        <option value="4-6">4-6 years</option>
                        <option value="7-10">7-10 years</option>
                        <option value="more-than-10">More than 10 years</option>
                      </select>
                    </div>
                  )}

                  {action === 'Institution' || action === 'Student' ? (
                    <div className="empty-div"></div>
                  ) : (
                    <div className="input">
                      <select
                        id="dropdown-menu"
                        onChange={(e) => setServices(e.target.value)}
                      >
                        <option
                          value="Services interested in"
                          selected
                          disabled
                          hidden
                        >
                          Services interested in
                        </option>
                        <option value="Admission Process Assistance">
                          Admission Process Assistance
                        </option>
                        <option value="Visa Guidance">Visa Guidance</option>
                        <option value="Post-Landing Support">
                          Post-Landing Support
                        </option>
                        <option value="Other (Specify)">Other (Specify)</option>
                      </select>
                    </div>
                  )}

                  {action === 'Institution' || action === 'Agent' ? (
                    <div className="empty-div"></div>
                  ) : (
                    <div className="input">
                      {/* <label htmlFor="education level"> Select Education Level </label> */}
                      <select
                        id="dropdown-menu"
                        onChange={(e) => setEducation(e.target.value)}
                      >
                        <option
                          value="Select Education Level"
                          selected
                          disabled
                          hidden
                        >
                          Select Education Level{' '}
                        </option>
                        <option value="undergraduate">Undergraduate</option>
                        <option value="postgraduate">Postgraduate</option>
                      </select>
                    </div>
                  )}

                  {action === 'Agent' ? (
                    <div className="empty-div"></div>
                  ) : (
                    <div className="input">
                      <input
                        type="text"
                        placeholder="Enter Your Country of Interest"
                        onChange={(e) => setInterest(e.target.value)}
                      />
                    </div>
                  )}

                  {action === 'Institution' || action === 'Agent' ? (
                    <div className="empty-div"></div>
                  ) : (
                    <div className="input">
                      <select
                        id="dropdown-menu"
                        onChange={(e) => setScore(e.target.value)}
                      >
                        <option
                          selected
                          disabled
                          hidden
                          value="Select English Proficiency"
                        >
                          Select English Proficiency
                        </option>
                        <option value="beginner">Beginner</option>
                        <option value="intermediate">Intermediate</option>
                        <option value="advanced">Advanced</option>
                      </select>
                    </div>
                  )}

                  {action === 'Student' || action === 'Agent' ? (
                    <div className="empty-div"></div>
                  ) : (
                    <div className="input">
                      <input
                        type="text"
                        placeholder="Additional Information"
                        onChange={(e) => setAddInformation(e.target.value)}
                      />
                    </div>
                  )}

                  <div className="contact-submit-btn">
                    <button type="submit">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;

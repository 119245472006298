import React from 'react'
import { HeroSection, OverviewSection, DetailedView, PartnerUniversity } from '../Components'
import Testimonials from '../Components/Testimonials/Testimonials'
// import OurTeam from '../Components/OurTeam/OurTeam'
import WhyUs from '../Components/WhyUs/WhyUs'
import HomeContact from '../Components/ContactForm/HomeContact'
import Frequently from '../Components/FAQs/Frequently'
// import ContactForm from '../Components'


const Home = () => {
    return (
        <>
            <HeroSection />
            <OverviewSection />
            <WhyUs/>
            <DetailedView />
            <PartnerUniversity />
            <Testimonials/>
            {/* <OurTeam/> */}
            {/* <ContactForm /> */}
            <HomeContact />
            <Frequently  />
        </>
    )
}

export default Home
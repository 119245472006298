import React from 'react'
import { FiMail, FiUser, FiPhone, FiMessageSquare } from "react-icons/fi";

const HomeContact = () => {
    return (
        <section className='home-contact' id='contact'>
            <div className="home-contact-container">
                <div className="home-contact-head">
                    <h2>Contact Us</h2>
                    <p>Any question or remarks? Just write a message!</p>
                </div>
                <div className='home-contact-main'>
                    <div className="home-contact-left">
                        <div className="home-form-content">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="name">
                                        <span className="icon-container">
                                        <FiUser className="form-icon" />
                                        </span>
                                         Name :
                                    </label>
                                    <input type="text" id="name" placeholder="Enter your name" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="number">
                                        <span className="icon-container">

                                        <FiPhone className="form-icon" />
                                        </span>
                                         Phone No. :
                                    </label>
                                    <input type="tel" id="number" placeholder="Enter your phone no." />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">
                                        <span className="icon-container">
                                        <FiMail className="form-icon" />
                                        </span>
                                         Email :
                                    </label>
                                    <input type="email" id="email" placeholder="Enter your email" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">
                                  <span className="icon-container">
     <FiMessageSquare className="form-icon" />
     </span> 
                                        Message :
                                    </label>
                                    <textarea id="message" placeholder="Enter your message"  rows={6}></textarea>
                                </div>
                                <div className="button-contact">
                                    <button type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="form-container-right">
                        <div className="image-content-right">
                            <img src="./Images/home-contact.gif" alt="home contact" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeContact
import React from 'react';
import { Twitter, Instagram, Facebook } from '../../icons';
import {CountryAspireLogo} from '../../icons';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-row">
            <div className="footer-logo">
              <CountryAspireLogo width={300} height={120} />
              <p>Email: contact@countryaspire.com</p>
              <div className="social-icons">
                <a href="#">
                  <Facebook />
                </a>
                <a href="#">
                  <Instagram />
                </a>
                <a href="#">
                  <Twitter />
                </a>
              </div>
            </div>
            <div className="footer-column">
              <h4>For Students</h4>
              <ul>
                <li>
                  <a href="#">Exam Prep</a>
                </li>
                <li>
                  <a href="#">Course Finder</a>
                </li>
                <li>
                  <a href="#">Counseling</a>
                </li>
                <li>
                  <a href="#">Blog</a>
                </li>
              </ul>
            </div>
            <div className="footer-column">
              <h4>For Institutions</h4>
              <ul>
                <li>
                  <a href="#">Why Country Aspire</a>
                </li>
                <li>
                  <a href="#">Partnered Universities</a>
                </li>
                <li>
                  <a href="#">Testimonials</a>
                </li>
                <li>
                  <a href="#">Service Provider</a>
                </li>
              </ul>
            </div>
            <div className="footer-column">
              <h4>About Country Aspire</h4>
              <ul>
                <li>
                  <a href="#">Our Story</a>
                </li>
                <li>
                  <a href="#">Careers</a>
                </li>
                <li>
                  <a href="#">Contact Us</a>
                </li>
                <li>
                  <a href="#">Branches</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <p className="reserved-line">
        All Right Reserved to Country Aspire &copy; {currentYear} || Country
        Aspire - Study Abroad consultant
      </p>
    </>
  );
};

export default Footer;

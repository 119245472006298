import { GrServices, GrBusinessService } from 'react-icons/gr';
import { MdOutlineDesignServices } from 'react-icons/md';
import { PiArrowBendRightDownBold } from 'react-icons/pi';
import { TbAward, TbBoxMultiple } from 'react-icons/tb';
import { GiAbstract042 } from 'react-icons/gi';
import {
  HiGlobe,
  HiLightBulb,
  HiOutlineViewGrid,
  HiShieldCheck,
} from 'react-icons/hi';

const ServicesData = [
  {
    id: 1,
    image: 'course.png',
    title: 'Assessment and Course Shortlisting',
    description:
      'Strategic advice for course selection based on your profile. Our profile assessment and course shortlisting service provides strategic advice for selecting courses based on your academic background and career goals, ensuring informed decisions and maximizing your potential. ',
  },
  {
    id: 2,
    image: 'discussion.png',
    title: 'One-on-One Counseling',
    description:
      'Personalized guidance tailored to your aspirations and goals. Our one-on-one counseling offers personalized guidance tailored to your aspirations and goals. Receive expert support to navigate your educational journey effectively and achieve your full potential. ',
  },
  {
    id: 3,
    image: 'documentation.png',
    title: 'Application and Admission Process',
    description:
      'Navigate the complex process with our expert assistance. Our application and admission process service guides you through the complexities of applying to universities and colleges, ensuring you meet requirements and maximize your chances of acceptance into desired institutions.',
  },
  {
    id: 4,
    image: 'travel.png',
    title: 'Visa Guidance',
    description:
      'Comprehensive support to ensure a smooth visa application process. Our visa guidance service provides comprehensive support to ensure a smooth and successful visa application process, helping you understand requirements, prepare documents, and navigate the intricacies of the application system confidently. ',
  },
  {
    id: 5,
    image: "inspiration.png",
    title: 'College Change Guidance',
    description:
      'Seamless transitions for students already studying abroad.Our college change guidance service offers expert advice and support for seamless transitions between educational institutions, assisting you in navigating changes and ensuring alignment with your academic aspirations. ',
  },
  {
    id: 6,
    image: "visa.png",
    title: 'Visa Extension Guidance',
    description:
      ' Assistance in extending your study visa for acontinued educational journey.Our visa extension guidance ensures a smooth continuation of your educational journey by providing comprehensive support for extending your study visa, including expert assistance and document preparation.',
  },
  {
    id: 7,
    image: "businessman.png",
    title: 'Post Graduate Work Permit (PGWP) Submission',
    description:
      'Expert guidance for a successful PGWP application.Our PGWP submission service offers expert guidance for a successful application process, ensuring all requirements are met and documentation is in order, empowering you to pursue post-graduation employment opportunities confidently. ',
  },
  {
    id: 8,
    image: "contract.png",
    title: 'PR Settlement from India to Canada',
    description:
      'Strategic pathways for long-term settlement. Our PR settlement and direct work permit service facilitates long-term settlement and employment opportunities in Canada from India, providing tailored guidance and support for a smooth transition to permanent residency and work authorization.',
  },
  {
    id: 9,
    image: "customer-service.png",
    title: 'Post-Landing Services',
    description:
      "From accommodation to local SIM cards, we've got you covered. Our post-landing services offer comprehensive support to ease your transition into a new country. From accommodation arrangements to obtaining local SIM cards, we provide personalized assistance to ensure a smooth settling-in process.",
  },
  {
    id: 10,
    image: 'note.png',
    title: 'SOP Writing Guidance',
    description:
      'Craft compelling Statements of Purpose for impactful applications. Our SOP writing guidance service offers expert support in crafting compelling Statements of Purpose for impactful university applications, helping you effectively articulate your academic and career aspirations to admissions committees. ',
  },
  {
    id: 11,
    image: 'abroad.png',
    title: 'Study Abroad Loams Assistance',
    description:
      'Secure your study abroad dreams with our expert assistance in navigating the financial landscape. We offer comprehensive support for obtaining study abroad loans, ensuring a smooth journey to success.',
  },
];

export default ServicesData;

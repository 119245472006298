import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { CountryAspireLogo } from '../../icons';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 1100 });
  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <CountryAspireLogo width={isMobile ? 160 : 200} height={isMobile ? 65 : 80} />
        </Link>
      </div>
      <nav
        className={`nav-links ${isMobileMenuOpen ? "mobile-menu-open" : ""}`}
      >
        <div className="dropdown">
          <a href="#" className="nav-link">
            Students &#9662;
          </a>
          <div className="dropdown-content">
            <a href="#whyusstudents" className="dropdown-link" onClick={() => setIsMobileMenuOpen(false)}>
              Why choose us?
            </a>
            <a href="/#partner-universities-section" className="dropdown-link" onClick={() => setIsMobileMenuOpen(false)}>
              Top Partner Universities
            </a>
            <a href="/services" className="dropdown-link" onClick={() => setIsMobileMenuOpen(false)}>
              Services
            </a>
            <a href="/destinations" className="dropdown-link" onClick={() => setIsMobileMenuOpen(false)}>
              Destinations
            </a>
          </div>
        </div>
        {/* <Link to="/recruitment-partner" className="nav-link">
          Recruitment Partners
        </Link> */}
        <Link to="/partner-universities" className="nav-link" onClick={() => setIsMobileMenuOpen(false)}>
          Partnered Universities
        </Link>
        <div className="dropdown">
          <a href="#" className="nav-link">
            About Us &#9662;
          </a>
          <div className="dropdown-content">
            <Link to="/ourStory" className="dropdown-link" onClick={() => setIsMobileMenuOpen(false)}>
              Our Story
            </Link>
            <Link to="#" className="dropdown-link" onClick={() => setIsMobileMenuOpen(false)}>
              Careers
            </Link>
          </div>
        </div>
        <Link to="/privacy-and-policy" className="nav-link" onClick={() => setIsMobileMenuOpen(false)}>
          Privacy Policy
        </Link>
        <div className="dropdown">
          <a href="#" className="nav-link">
            Contact &#9662;
          </a>
          <div className="dropdown-content">
            <Link to="/contact/student" className="dropdown-link" onClick={() => setIsMobileMenuOpen(false)}>
              As a student
            </Link>
            <Link to="/contact/institution" className="dropdown-link" onClick={() => setIsMobileMenuOpen(false)}>
              As a Institution
            </Link>
            <Link to="/contact/agent" className="dropdown-link" onClick={() => setIsMobileMenuOpen(false)}>
              As a Recruiter
            </Link>
          </div>
        </div>
        {
          isMobile &&
          <a href="#contact" className="nav-link" onClick={() => setIsMobileMenuOpen(false)}>Contact Us</a>
        }
      </nav>
      <div className="contact-nav">
        <a href="#contact" >
          Contact Us
        </a>
      </div>
      <div
        className={`mobile-menu-icon ${isMobileMenuOpen ? "active" : ""}`}
        onClick={handleMobileMenuToggle}
      >
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </header>
  );
};

export { Header };
